import { useEffect, useRef, useState, useContext } from 'react';
import { v4 as uuidv4 } from 'uuid';
import db from '@services/firebase-service';
import { doc, onSnapshot } from 'firebase/firestore';
import useRatingPollsCanvas from './hooks/useRatingPollsCanvas';
import { stopEngagement } from '@services/youtube-platform.service';
import { StreamDataContext } from '@components/context/StreamContext';
import { logToCloudWatch } from '@services/logger.service';
import Container from '@atoms/Container';
import { handleEngagement } from '@services/interactions-service';

const defaultRatingPollsOptions = Array.from({ length: 5 }, (_, i) => ({
  id: uuidv4(),
  body: ` ${i + 1}`,
  option_number: i + 1,
  count: 0,

}));

const initialRating = {
  averageRating: '0.0',
  contribution: defaultRatingPollsOptions.map((option) => ({
    option_number: option.option_number,
    percentage: 0,
    count: 0,
    body: option.body
  }))
};

const RatingPollsResult = (props: any) => {
  const { analyticsData: { data, id, liveStreamId, setting, color } } = props;
  const feature = {
    id,
    setting,
    settings: {
      ...setting
    },
    color
  };
  const { containerRef } = useRatingPollsCanvas(data, feature, liveStreamId, `ratingPollsAnalytics-${id}`, true);
  return (
    <Container className="bg-neutral-80 px-6 pb-2 pt-2 h-116 flex flex-col shadow-md-1 rounded-lg justify-center item-center">
      <div ref={containerRef} className='ratingPollWrapper w-full h-full ' >
        <canvas id={`ratingPollsAnalytics-${id}`} />
      </div>
    </Container>
  );
};

const RatingPolls = ({
  streamId, currentRosResult, currentRosFeature, colorData, streamSettings, platformType,
  startInteraction, setCurrentFeatureId, interactionType, isImpromptu,
  setShowRosModel, activeInteractionRef, updateFSMeta, setShowNotification,
  isAnalytics = false, analyticsData = {}, setStartTime
}: any) => {
  if (isAnalytics) return <RatingPollsResult analyticsData={analyticsData} />;
  const {
    firestoreUnsub, setFirestoreUnsub
  } = useContext(StreamDataContext);

  const { isRos, isResult } = interactionType;
  const [ratingPollsId, setRatingPollsId] = useState(null);
  const [rating, setRating] = useState(initialRating);
  const totalCommentsRef: any = useRef([]);
  const [, setFeatureIdUpdated] = useState(false);
  const [previousData, setPreviousData] = useState<any>(null);

  const ratingPoll = previousData ? {
    data: previousData,
    ...previousData
  } : {
    data: rating,
    ...currentRosFeature
  };
  const { containerRef } = useRatingPollsCanvas(ratingPoll.data, ratingPoll, streamId, 'ratingPollCanvas');

  const setupRatingPolls = ({ id, current, prevData }: any) => {
    totalCommentsRef.current = current;
    setRating(prevData);
    setRatingPollsId(id);
    setCurrentFeatureId(id);
    setPreviousData(null);
  };

  const handleRatingPolls = async ({ interactionId = null }) => {
    const response = await handleEngagement({ streamId, platformType, currentRosFeature, interactionId });

    if (response.status && response.entity) {
      const interactionDetails = {
        id: response.entity.engagementId,
        type: 'ratingPolls',
        prevData: initialRating
      };
      setupRatingPolls(interactionDetails);
      updateFSMeta({ activeInteraction: interactionDetails });
    }
  };

  const updateRatingPolls = () => {
    if (activeInteractionRef.current && activeInteractionRef.current?.type === 'ratingPolls') {
      setupRatingPolls({
        id: activeInteractionRef.current.id,
        current: [],
        counter: 0,
        prevData: initialRating
      });
    }
  };

  useEffect(() => {
    setShowRosModel(false);
    if (isImpromptu) {
      setPreviousData(null);
    }
    if (isRos && currentRosResult.length) {
      setPreviousData(currentRosResult[0]);
    } else {
      setPreviousData(currentRosFeature);
    }
    setRatingPollsId(currentRosFeature.id);
  }, [currentRosFeature, currentRosResult]);

  useEffect(() => {
    if (activeInteractionRef.current) {
      updateRatingPolls();
    }
  }, [activeInteractionRef.current]);

  const unsubscribeFromFirestore = () => {
    if (firestoreUnsub.unsub) {
      firestoreUnsub.unsub();
    }
  };

  const stopRatingPolls = async () => {
    unsubscribeFromFirestore();
    const sessionId = localStorage.getItem('sessionId');
    await stopEngagement(streamId, 'ratingPolls', { engagementId: ratingPollsId, platformType, sessionId });
  };

  const handleStartInteraction = () => {
    if (currentRosResult && currentRosResult.length > 0) {
      handleRatingPolls({ interactionId: currentRosResult[0].id });
    } else {
      handleRatingPolls({ interactionId: null });
    }
    if (activeInteractionRef.current) {
      updateRatingPolls();
    }
    setStartTime(Date.now());
  };

  const handleStopInteraction = () => {
    stopRatingPolls();
    setRatingPollsId(null);
  };

  const manageRatingPollsInteraction = () => {
    if (!isResult && startInteraction) {
      handleStartInteraction();
    } else if (ratingPollsId) {
      handleStopInteraction();
    }
  };

  useEffect(() => {
    setShowRosModel(false);
    manageRatingPollsInteraction();
  }, [currentRosFeature.id, startInteraction]);

  useEffect(() => {
    setFeatureIdUpdated(true);
    if (ratingPollsId) {
      const logData = {
        streamId,
        interactionType: 'ratingPolls',
        interactionId: ratingPollsId
      };
      setFirestoreUnsub({
        unSub: onSnapshot(doc(db, 'streams', streamId, 'ratingPolls', ratingPollsId), (document) => {
          logToCloudWatch('Successfully subscribed to firestore', logData, 'INFO');
          if (document.exists()) {
            setFeatureIdUpdated(false);
            const { data: rating } = document.data();
            if (rating) {
              setShowNotification(false);
              setRating({ averageRating: rating?.averageRating || 0.0, contribution: rating?.contribution });
            }
          }
        }, (error) => logToCloudWatch('Failed to subscribe to firestore', { ...logData, error }, 'ERROR'))
      });
    }
    return () => {
      unsubscribeFromFirestore();
    };
  }, [ratingPollsId]);

  return (
    <div ref={containerRef} className='ratingPollWrapper w-full h-full ' >
      <canvas id='ratingPollCanvas' />
    </div>
  );
};

export default RatingPolls;
