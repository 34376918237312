import { colorPalette as colors } from '@lib/constants';
import { chromaPaletteParser } from '../helper/chromaPaletteParser';
import FabricTile from './createTile';
import { groupBy } from 'lodash';
export const isColliding = (box1, box2, margin = 5) => {
  if (box1.data.column !== box2.data.column) return false;
  const expandedBox1 = {
    top: box1.top - margin,
    height: box1.height + margin * 2
  };

  const expandedBox2 = {
    top: box2.top - margin,
    height: box2.height + margin * 2
  };

  return expandedBox1.top < expandedBox2.top + expandedBox2.height &&
    expandedBox1.top + expandedBox1.height > expandedBox2.top;
};

export const getFontColor = (backgroundColor) => {
  if (backgroundColor.charAt(0) === '#') {
    backgroundColor = backgroundColor.slice(1);
  }

  const red = parseInt(backgroundColor.substr(0, 2), 16);
  const green = parseInt(backgroundColor.substr(2, 2), 16);
  const blue = parseInt(backgroundColor.substr(4, 2), 16);

  const brightness = (red * 299 + green * 587 + blue * 114) / 1000;

  if (brightness > 125) {
    return '#000000';
  }

  return '#FFFFFF';
};

export class CanvasManager {
  static instance;
  constructor(canvas, container, currentRosFeature, chromaColor) {
    this.canvas = canvas || CanvasManager.instance.canvas;
    this.container = container;
    this.currentRosFeature = currentRosFeature;
    this.chromaColor = chromaColor;
    this.colorPalette = chromaPaletteParser().parse(colors, chromaColor);
    this.commentCounter = 0;
    this.count = 0;
    this.lastColors = new Array(3).fill('');
    this.animating = true;
    this.duration = 10;
    this.timeout = 15;
    this.startPosition = -50;

    CanvasManager.instance = this;
  }

  initializeCanvas() {
    ['object:moving', 'object:scaling', 'object:rotating', 'mouse:down', 'mouse:up',
      'mouse:move', 'mouse:wheel', 'mouse:dblclick', 'selection:cleared',
      'selection:created', 'mouse:singleclick'].forEach(event => this.canvas.off(event));

    const defaultBgColor = this.currentRosFeature?.color?.talkingTilesBgColor || '#000000';
    const backgroundColor = this.currentRosFeature?.color?.isChroma ? this.currentRosFeature.color.chromaColor : defaultBgColor;
    this.canvas.setBackgroundColor(backgroundColor);

    const height = this.container.getBoundingClientRect().height;
    const width = this.container.getBoundingClientRect().width;
    this.canvas.setHeight(height);
    this.canvas.setWidth(width);
  }

  drawResultTile({ resultData, numCols }) {
    const hasRequiredProps = !!resultData || !!numCols || !!this.canvas || !!this.container || !!this.currentRosFeature;
    if (!hasRequiredProps) return;
    this.canvas?.clear();
    const containerWidth = this.container?.getBoundingClientRect().width;
    const containerHeight = this.container?.getBoundingClientRect().height;

    numCols = containerWidth >= 768 ? 3 : containerWidth < 480 ? 1 : 2;

    const heightArr = Array(numCols).fill(20);
    const reversedComments = [...resultData].reverse();
    const comments = resultData.length > 0 ? reversedComments : [];

    const padding = 8;

    const filterComments = comments.filter((comment) => comment?.words?.trim());
    filterComments.forEach((comment, index) => {
      const tilesInCanvas = this.canvas.getObjects();
      const colIndex = index % numCols;

      const xAxis = tilesInCanvas.length > 0 ? colIndex * (tilesInCanvas[0].width + padding) : 0;
      const yAxis = heightArr[colIndex];

      const backgroundColor = this.getRandomColor(colIndex);
      const latestTile = this.showResult({
        comment,
        backgroundColor,
        xAxis,
        yAxis,
        index,
        count: this.count,
        containerWidth,
        numCols,
      });
      latestTile.set('top', containerHeight - yAxis - latestTile.height);
      heightArr[colIndex] += latestTile.height + padding;
    });

    const maxHeight = Math.max(...heightArr, containerHeight);
    this.canvas.setHeight(maxHeight);
    this.canvas.setWidth(containerWidth);

    if (maxHeight > containerHeight) {
      this.container.style.overflowY = 'scroll';
      const currTiles = this.canvas.getObjects();

      currTiles.forEach((tile) => {
        tile.set('top', tile.top + maxHeight - containerHeight);
      });
    }
    this.container.scrollTop = maxHeight;
    this.canvas.renderAll();
  }

  showResult({ comment, backgroundColor, xAxis, yAxis, count, containerWidth, numCols }) {
    const width = (containerWidth / numCols) - 15;

    const tile = new FabricTile(
      xAxis + 20,
      yAxis,
      comment?.words || comment,
      backgroundColor,
      width,
      this.currentRosFeature?.setting?.showUserName ? comment?.username : '',
      `${count}`,
      '0'
    ).initialize();

    this.canvas.add(tile);
    return tile;
  }

  dropTile({ comments, numCols, isResizing = false }) {
    if (!this.canvas || !this.container) return;
    const hasRequiredProps = !!comments || !!numCols;
    if (!hasRequiredProps) return;
    const height = this.container?.getBoundingClientRect()?.height;
    const width = this.container?.getBoundingClientRect()?.width;
    this.canvas?.setHeight(height);
    this.canvas?.setWidth(width);

    numCols = width >= 768 ? 3 : width < 480 ? 1 : 2;
    const columnValues = Array.from({ length: numCols }, (_, i) => i * (width / numCols) + 8);
    const filterComments = comments?.filter((comment) => comment?.words?.trim());
    if (filterComments?.length > 0 && filterComments.length > this.commentCounter) {
      const sortedComments = isResizing ? filterComments.slice(0, comments.length - this.commentCounter) : filterComments.slice(0, comments.length - this.commentCounter).reverse();

      sortedComments.forEach((comment, index) => {
        this.count += 1;
        const backgroundColor = this.getRandomColor(index);

        const canvasTiles = this.canvas.getObjects();
        const tilesInColumn = groupBy(canvasTiles, 'data.column');

        const colHeight = Array.from({ length: numCols }, (_, i) => 0);

        Object.keys(tilesInColumn).forEach((key) => {
          if (!key) return;
          const columnTiles = tilesInColumn[key] || [];
          colHeight[key] = columnTiles.reduce((acc, curr) => acc + curr.height, 0);
        });

        const smallestColumnHeight = Math.min(...colHeight);

        let columnWithLeastTiles = colHeight.findIndex((height) => height === smallestColumnHeight);

        if (columnWithLeastTiles === -1) {
          columnWithLeastTiles = 0;
        }

        const xAxis = columnValues[columnWithLeastTiles];

        const words = comment?.words || comment;
        const username = comment?.username || '';
        const tile = new FabricTile(
          xAxis,
          this.startPosition,
          words,
          backgroundColor,
          (width / numCols - 15),
          this.currentRosFeature?.setting?.showUserName ? username : '',
          `${this.count}`,
          `${columnWithLeastTiles}`
        ).initialize();

        tile.top = -tile.height;

        this.canvas.add(tile);
        this.keepOnMovingDown(tile, numCols);
      });
      this.commentCounter = filterComments.length;
    }
  }

  getRandomColor(columnIndex) {
    const defaultColors = [
      this.currentRosFeature?.color?.talkingTileBgColor1,
      this.currentRosFeature?.color?.talkingTileBgColor2,
      this.currentRosFeature?.color?.talkingTileBgColor3
    ];

    let availableColors = !this.currentRosFeature?.color?.multipleColors ? defaultColors : this.colorPalette;

    availableColors = availableColors.filter(color => color !== this.lastColors[columnIndex]);

    if (availableColors.length === 0) {
      availableColors = (!this.currentRosFeature?.color?.multipleColors ? defaultColors : this.colorPalette).filter(color => color !== this.lastColors[columnIndex]);
    }

    const randomColor = availableColors[Math.floor(Math.random() * availableColors.length)];
    this.lastColors[columnIndex] = randomColor;

    return randomColor;
  }

  keepOnMovingDown (drawnTile, numCols) {
    const animate = () => {
      const containerHeight = this.container?.getBoundingClientRect()?.height;

      const isCollidingWithOtherTiles = this.canvas.getObjects()?.some((t) => {
        if (t.top === this.startPosition && drawnTile.top === this.startPosition && (t.data.count) < drawnTile.data.count) return true;
        if (parseInt(t.data.count) < parseInt(drawnTile.data.count)) {
          return isColliding(drawnTile, t);
        } else return false;
      });

      const hasNotReachedBottom = drawnTile.top < (containerHeight - drawnTile.height) - 20;

      const thresholdHeight = containerHeight * 0.5;

      const bottomHeight = containerHeight - 22;

      const minHeight = this.canvas.getObjects()?.reduce((acc, curr) => {
        if (curr?.data?.collided && curr.top + curr.height > 0) {
          return Math.min(acc, curr.top);
        } else return acc;
      }, 1000);

      if (minHeight < thresholdHeight) {
        const bottomTiles = [];
        let fulRow = true;
        for (let i = 0; i < numCols; i++) {
          const colBottomTile = this.canvas.getObjects().find((t) => {
            return t.top + t.height > bottomHeight && t.data.column === i.toString();
          });

          if (!colBottomTile) {
            fulRow = false;
            break;
          }

          bottomTiles.push(colBottomTile);
        }

        if (fulRow) {
          bottomTiles.forEach((t) => {
            this.canvas.remove(t);
          });
        }
      }

      drawnTile.set('data', { collided: isCollidingWithOtherTiles, column: drawnTile.data.column, username: drawnTile?.data?.username || '', words: drawnTile?.data?.words || '', count: drawnTile?.data?.count || 0 });

      if (!isCollidingWithOtherTiles && hasNotReachedBottom
      ) {
        drawnTile.top += 1.5;
        requestAnimationFrame(animate);
      }
      if (isCollidingWithOtherTiles) {
        setTimeout(() => {
          requestAnimationFrame(animate);
        }, this.timeout);
      };
    };

    requestAnimationFrame(animate);
  };

  setCanvasHeightToContainerHeight() {
    const { height, width } = this.container.getBoundingClientRect();
    this.canvas.setHeight(height);
    this.canvas.setWidth(width);
    this.container.removeScroll = true;
  }

  resetCanvas(canvas, container, currentRosFeature) {
    this.animating = false;

    if (!this.canvas) {
      this.canvas = canvas;
      this.container = container;
      this.currentRosFeature = currentRosFeature;
    }
    this.canvas.clear();

    const newInstance = new CanvasManager(canvas, container, currentRosFeature, this.chromaColor);

    this.initializeCanvas();
    CanvasManager.instance = newInstance;

    setTimeout(() => {
      newInstance.animating = true;
    }, 100);

    return newInstance;
  }

  resizeCanvas(container) {
    this.canvas.setHeight(container.getBoundingClientRect().height);
    this.canvas.setWidth(container.getBoundingClientRect().width);
  }

  reanimateTiles(numCols) {
    this.commentCounter = 0;
    const comments = this.getVisibleTilesComments();
    this.canvas.clear();

    this.dropTile({ comments, numCols, isResizing: true });
  }

  clearCanvas() {
    if (this.canvas) {
      this.canvas.clear();
    }
  }

  getVisibleTilesComments () {
    return this.canvas?.getObjects()?.map((obj) => ({ words: obj?.data?.words, username: obj?.data?.username || '' }));
  }

  setCurrentRosFeature(currentRosFeature) {
    this.currentRosFeature = currentRosFeature;
  }

  setBackgroundColor(color) {
    if (this?.canvas) {
      this.canvas.setBackgroundColor(color);
    };
  }
}
