import { fabric } from 'fabric';
import { getFontColor } from './helper';

const TEXT_MAX_LENGTH = 400;
class FabricTile {
  constructor(x, y, text, color, width, username, count, column) {
    this.padding = 20;
    this.x = x;
    this.y = y;
    this.color = color;
    this.width = width;
    this.text = text?.trim();
    this.username = username?.trim();
    this.column = column;
    this.count = count;
    this.font = 'Montserrat';
    this.radius = 10;
  }

  initialize() {
    const text = `${this?.text?.length > TEXT_MAX_LENGTH ? this?.text?.slice(0, TEXT_MAX_LENGTH) + '...' : this?.text || ''}`;
    const textbox = new fabric.Textbox(text, {
      left: this.x + (this.padding),
      top: this.y + this.padding + 10,
      width: this.width - 2 * this.padding,
      fontFamily: this.font,
      fontSize: this.width * 0.05,
      textAlign: 'center',
      fill: getFontColor(this.color),
      useNative: true,
      splitByGrapheme: true,
      caching: false,
      breakWords: true,
      originX: 'left',

    });

    const textBoxScaledHeight = textbox?.getScaledHeight();
    textbox.set({ height: textBoxScaledHeight, fontFamily: 'Montserrat' });

    let usernameText;
    let usernameRect;

    if (this.username) {
      usernameText = new fabric.Text(this?.username, {
        left: 0,
        top: this.y + textBoxScaledHeight + 55,
        fill: 'white',
        fontFamily: this.font,
        fontSize: this.width * 0.025,
        textAlign: 'center',
        originX: 'left',
        originY: 'top',
      });

      const MIN_USERNAME_IN_PIXELS = 100;
      let usernameTextWidth = usernameText?.getScaledWidth();

      const ratio = usernameTextWidth / (this.width * 0.7);
      const usernameRation = this.username.length / ratio;

      if (ratio > 1) {
        usernameTextWidth = usernameTextWidth / ratio;
        usernameText.set({ text: `${this.username.slice(0, usernameRation)}...` });
      }
      const maxUsernameWidth = Math.max(usernameTextWidth, MIN_USERNAME_IN_PIXELS);

      const usernameWidth = Math.min(this.width * 0.7, maxUsernameWidth);

      usernameText.set({ left: this.x + this.width - usernameWidth - 20 });

      usernameRect = new fabric.Rect({
        left: usernameText?.left - 10,
        top: this.y + textBoxScaledHeight + 45,
        fill: 'black',
        width: usernameWidth + 20,
        height: usernameText?.getScaledHeight() + 15,
        rx: this.radius,
        ry: this.radius,
        originX: 'left',
        originY: 'top',
        lockScalingFlip: true,
        lockMovementX: true,
        lockMovementY: true,
        selectable: false
      });

      usernameRect.sendToBack();
    }

    const tileHeight = usernameText ? textBoxScaledHeight + 90 : textBoxScaledHeight + 32;
    const rect = new fabric.Rect({
      left: this.x,
      top: this.y,
      fill: this.color,
      width: this.width,
      height: tileHeight,
      rx: this.radius,
      ry: this.radius,
      originX: 'left',
      originY: 'top',
    });

    rect.sendToBack();

    const tile = new fabric.Group(usernameText ? [rect, textbox, usernameRect, usernameText] : [rect, textbox], {
      left: this.x,
      top: this.y,
      selectable: false,
      originX: 'left',
      originY: 'top',
      moveCursor: 'pointer',
      hoverCursor: 'pointer',

      data: {
        collided: false,
        column: this.column,
        username: this.username || '',
        words: this.text,
        count: this.count,
      },

    });

    return tile;
  }

  getTile() {
    return this.tile;
  }
}

export default FabricTile;
